
import { getDictFLJ,
         getNewVfaDate } from '~/utils/butterUtils'
import { MS_PER_DAY, getVFAParameters } from '~/utils/VFAParameters'

export default {
  props: [
    'isElections',
    'deadlineElection',
    'dict',
    'selectedState',
    'voterType',
    'voterTypeDescription'
  ],
  data () {
    return {
      voterIsRegistered: '',
      selectedvoterIsRegistered: false
    }
  },
  computed: {
    showTestData () {
      return this.$store.state.showTestData
    },
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    dateFormat () {
      return this.$i18n.locales.filter(item => item.code === this.$i18n.locale)[0].iso
    },
    today () {
      return getNewVfaDate().getTime()
    },
    deadlineBallotReturnDay () {
      return (new Date(this.deadlineElection.rules.ballotreturn[0].date)).getTime()
    },
    deadlinesRegistration () {
      return this.deadlineElection.rules.registration.filter(x=>x.date)
    },
    electionDayM0 () {
      return (new Date(this.deadlineElection.date)).getTime()
    },
    electionDayM21 () {
      return this.electionDayM0 - this.FWAB_TRIGGER_DEFAULT*MS_PER_DAY
    },
    electionDayM43 () {
      return this.electionDayM0 - (this.LEO_SENDOUT_BALLOTS_TRIGGER-2)*MS_PER_DAY
    },
    electionDateM43 () {
      return (new Date(this.electionDayM43)).toISOString().slice(0,10)
    },
    electionDayM45 () {
      return this.electionDayM0 - this.LEO_SENDOUT_BALLOTS_TRIGGER*MS_PER_DAY
    },
    VFAParameters () {
      return getVFAParameters(this.$store)
    },
    FWAB_TRIGGER_DEFAULT () {
      return this.VFAParameters.FWAB_TRIGGER_DEFAULT
    },
    LEO_SENDOUT_BALLOTS_TRIGGER () {
      return this.VFAParameters.LEO_SENDOUT_BALLOTS_TRIGGER
    },
  },
  methods: {
    anonymousStart () {
      this.$buefy.toast.open({
        message: this.dict.D00,
        type: 'is-success',
        duration: 8000
      })
      this.$store.commit('saveVoterFWAB', true)

      sessionStorage.removeItem('sessionstoragephones')
      this.$store.commit('clearVoterData')
      this.$store.dispatch('userdata/clearVoterData')
      this.$router.push(this.localePath({ name: 'request-stage', params :{ stage: 'start-fpca' } }))
    },
    saveRegType () {
      this.selectedvoterIsRegistered=true
      this.$store.commit('saveVoterIsRegistered', this.voterIsRegistered)
    },
    getDictFLJ(dictItem, dict) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictFLJ(dictItem, dict)" in the return statement is really
       * the function getDictFLJ(dictItem, dict) that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictFLJ(dictItem, dict)
    },
  }
}
